import { setSkuNotify } from '@/store/slices/notifyMeSlice/notifyMeSlice';
import { useAppDispatch } from '@/store/store';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import Image from 'next/image';

interface Props {
  isShort?: boolean;
  sku?: string;
  skuStatus?: string;
}

export default function OutOfStockBadge({ isShort, sku, skuStatus }: Props) {
  const { t } = useTranslation('productcard');
  const dispatch = useAppDispatch();
  const [isHover, setIsHover] = useState<boolean>(false);
  const handleOnClickNotify = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(setSkuNotify(sku));
  };

  return (
    <div
      className={`text-sm flex items-center justify-center text-white rounded-full bg-black bg-opacity-40 ${
        !isShort ? 'sm:w-20' : ''
      } ${
        isHover && skuStatus !== 'D' ? 'sm:w-28' : ''
      } select-none whitespace-nowrap`}
      style={{
        maxLines: 1,
      }}
      onClick={skuStatus !== 'D' ? handleOnClickNotify : undefined}
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      {isHover && skuStatus !== 'D' ? (
        <div className="px-1">
          <div className="flex justify-center items-center">
            <div className="w-full">
              <Image
                src="/images/logo/bellnotify.svg"
                alt="bellnotify"
                width={24}
                height={24}
              />
            </div>
            <div className="ps-0.5">{t('notifywheninstock')}</div>
          </div>
        </div>
      ) : (
        <div className="px-1">
          {skuStatus !== 'D' ? t('outofstock') : t('common:outofstock')}
        </div>
      )}
    </div>
  );
}
