// import { Tooltip } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import Image from 'next/image';
// import Spinner from '@/components/common/Spinner';
// import { GetPDPFreeGiftModel } from '@/models/pdp/GetPdpFreeGift.model';
// import { getPdpFreeGiftData } from '@/services/client/pdp/getPdpFreeGiftService';

interface Props {
  sku: string;
}

export default function FreeItemBadge({}: Props) {
  const { t } = useTranslation('');
  // const [showTooltip, setShowTooltip] = useState<boolean>(false);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [freeGifts, setFreeGifts] = useState<
  //   GetPDPFreeGiftModel[] | undefined | null
  // >(undefined);

  // useEffect(() => {
  //   if (showTooltip && freeGifts === undefined) {
  //     setIsLoading(true);
  //     getPdpFreeGiftData({
  //       lang: i18n.language,
  //       skcode: sku,
  //     })
  //       .then((res) => {
  //         setFreeGifts(res);
  //         setIsLoading(false);
  //       })
  //       .catch(() => {
  //         setFreeGifts(null);
  //         setIsLoading(false);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showTooltip]);

  return (
    // <Tooltip
    //   open={showTooltip}
    //   onOpen={() => setShowTooltip(true)}
    //   onClose={() => setShowTooltip(false)}
    //   leaveTouchDelay={0}
    //   title={
    //     <div
    //       onClick={(e) => {
    //         e.preventDefault();
    //       }}
    //       className="pb-1 w-20 px-1 rounded-sm bg-white"
    //       style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 5px' }}
    //     >
    //       <div className="flex justify-center items-center pt-1 min-h-[4.5rem]">
    //         {isLoading ? (
    //           <Spinner color="black" borderSize={2} />
    //         ) : freeGifts && freeGifts.length > 0 ? (
    //           <div className="flex flex-col items-center space-y-0.5">
    //             <div className="m-0 text-xs text-secondaryOrange">
    //               {`${freeGifts[0].buyMsg} ${t('free')}`}
    //             </div>
    //             <div style={{ position: 'relative' }}>
    //               <Image
    //                 src={`data:image/jpeg;base64,${freeGifts[0].base64}`}
    //                 alt={freeGifts[0].prName ?? ''}
    //                 width={50}
    //                 height={50}
    //                 style={{ margin: 'auto' }}
    //               />
    //             </div>
    //             <div className="text-secondaryPurple">
    //               x{freeGifts[0].quant}
    //             </div>
    //           </div>
    //         ) : (
    //           <>No Data.</>
    //         )}
    //       </div>
    //     </div>
    //   }
    //   placement="top"
    //   arrow
    //   componentsProps={{
    //     tooltip: {
    //       className: 'bg-white text-black py-0 w-full tooltip-arrow px-0',
    //     },
    //   }}
    // >
    <div
      // onClick={(e) => {
      //   setShowTooltip(!showTooltip);
      //   e.preventDefault();
      // }}
      className="relative w-7 sm:w-10 lg:w-auto "
    >
      <div>
        <Image
          src="/images/svg/plp/gift.svg"
          alt="gift"
          width={60}
          height={60}
          style={{ margin: 'auto' }}
        />
      </div>
      <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-white text-center">
        <div className="font-semibold text-sm sm:text-lg lg:text-xl leading-[0.5rem] sm:leading-3 lg:leading-3 pt-2.5">
          {t('productcard:free')}
        </div>
        <div className="text-xxs sm:text-xs lg:text-xs whitespace-nowrap leading-[0.6rem] sm:leading-3 lg:leading-3">
          {t('productcard:freebies')}
        </div>
      </div>
    </div>
    // </Tooltip>
  );
}
