import {
  compareResultsSelector,
  mutateCompare,
} from '@/store/slices/compareSlice';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  sku: string;
  pimImage?: string;
  slugname: string;
  size?: number;
  urlimg?: string;
  isNotText?: boolean;
  xsShowText?: boolean;
  isShort?: boolean;
}

export default function CompareProduct({
  sku,
  pimImage,
  slugname,
  size = 0.8,
  urlimg,
  isNotText,
  xsShowText,
  isShort,
}: Props) {
  const compareResults = useSelector(compareResultsSelector);
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const compareClick = (e: any) => {
    e.preventDefault();
    dispatch(
      mutateCompare({
        sku,
        pimImage,
        slugname,
        urlimg,
      }),
    );
  };
  const isActive = compareResults.find((item) => item.sku === sku) != null;

  return (
    <div onClick={compareClick} className="cursor-pointer">
      <div className={isShort ? 'flex' : 'flex lg:hidden'}>
        {isActive ? (
          <svg
            width="19"
            height="19"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={isShort ? 'mt-[2px]' : 'my-auto'}
          >
            <rect x="7" y="1" width="16" height="16" rx="1" fill="#B8292F" />
            <rect
              x="7"
              y="1"
              width="16"
              height="16"
              rx="1"
              stroke="white"
              strokeWidth="1"
            />
            <rect x="1" y="7" width="16" height="16" rx="1" fill="#B8292F" />
            <rect
              x="1"
              y="7"
              width="16"
              height="16"
              rx="1"
              stroke="white"
              strokeWidth="1"
            />
          </svg>
        ) : (
          <svg
            className={isShort ? 'mt-[2px]' : 'my-auto'}
            width="19"
            height="19"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="7"
              y="1.10254"
              width="16"
              height="16"
              rx="1"
              fill="white"
            />
            <rect
              x="7"
              y="1.10254"
              width="16"
              height="16"
              rx="1"
              stroke="#B4B4B4"
              strokeWidth="2"
            />
            <rect
              x="1"
              y="7.10254"
              width="16"
              height="16"
              rx="1"
              fill="white"
            />
            <rect
              x="1"
              y="7.10254"
              width="16"
              height="16"
              rx="1"
              stroke="#B4B4B4"
              strokeWidth="2"
            />
          </svg>
        )}
        {xsShowText && <span className="ms-2">{t('compare')}</span>}
      </div>
      <div className={isShort ? 'hidden' : 'hidden lg:flex items-center'}>
        {isActive ? (
          <svg
            width="19"
            height="19"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={isShort ? 'mt-[2px]' : ''}
          >
            <rect x="7" y="1" width="16" height="16" rx="1" fill="#B8292F" />
            <rect
              x="7"
              y="1"
              width="16"
              height="16"
              rx="1"
              stroke="white"
              strokeWidth="1"
            />
            <rect x="1" y="7" width="16" height="16" rx="1" fill="#B8292F" />
            <rect
              x="1"
              y="7"
              width="16"
              height="16"
              rx="1"
              stroke="white"
              strokeWidth="1"
            />
          </svg>
        ) : (
          <svg
            className={isShort ? 'mt-[2px]' : ''}
            width="19"
            height="19"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="7"
              y="1.10254"
              width="16"
              height="16"
              rx="1"
              fill="white"
            />
            <rect
              x="7"
              y="1.10254"
              width="16"
              height="16"
              rx="1"
              stroke="#B4B4B4"
              strokeWidth="2"
            />
            <rect
              x="1"
              y="7.10254"
              width="16"
              height="16"
              rx="1"
              fill="white"
            />
            <rect
              x="1"
              y="7.10254"
              width="16"
              height="16"
              rx="1"
              stroke="#B4B4B4"
              strokeWidth="2"
            />
          </svg>
        )}
        <label
          htmlFor={`compare-${sku}`}
          className="cursor-pointer ms-1 mt-[0.11rem] text-black line-clamp-1"
          style={{
            fontSize: `1rem`,
            lineHeight: `${(size ?? 0.75) + (size ?? 0.75) / 2}rem`,
            height: `${(size ?? 0.75) + (size ?? 0.75) / 2}rem`,
          }}
        >
          {!isNotText && !isShort && t('compare')}
        </label>
      </div>
    </div>
  );
}
