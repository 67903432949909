import Button from '@mui/material/Button';
import { useTranslation } from 'next-i18next';
import React from 'react';

interface Props {
  height?: number;
  isNoShort?: boolean;
}

export default function OutOfStockButton({ height = 1.75, isNoShort }: Props) {
  const { t } = useTranslation();
  return (
    <Button
      disabled={true}
      className={`text-sm bg-[#EBEBEB] disabled:bg-[#EBEBEB] h-[${height}rem] leading-3 w-full ${
        isNoShort ? '' : 'sm:w-auto'
      } min-w-0`}
      sx={{
        backgroundColor: '#EBEBEB',
        '&:disabled': {
          backgroundColor: '#EBEBEB',
        },
        height: `${height}rem`,
        fontSize: '0.75rem',
        lineHeight: '0.75rem',
        minWidth: '0',
        width: '100%',
        sm: {
          width: isNoShort ? '100%' : 'auto',
        },
      }}
      aria-label="out of stock"
    >
      <div className="overflow-hidden w-full line-clamp-2 px-0 sm:px-1.5">
        {t('common:outofstock')}
      </div>
    </Button>
  );
}
