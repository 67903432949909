import React from 'react';
import Image from 'next/image';

interface Props {
  point: number;
}

export default function The1PointXBadge({ point }: Props) {
  return (
    <div className="relative w-6 h-6 sm:w-8 sm:h-8 lg:w-9 lg:h-9 xl:w-10 xl:h-10 border rounded">
      <Image
        width={60}
        height={60}
        alt="the1pointx"
        src="/images/svg/plp/the1point.svg"
        className="w-full h-full rounded-lg"
      />
      <div className="text-white absolute -translate-x-1/2 -bottom-1 sm:-bottom-0.5 lg:bottom-0 left-1/2 z-30">
        <span className="text-xxs sm:text-xs">x</span>
        <span className="font-bold ms-0.5 text-xs sm:text-sm md:text-lg leading-3 sm:leading-4 md:leading-4">
          {point}
        </span>
      </div>
    </div>
  );
}
