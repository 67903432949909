import { postDataFromAPI } from '@/utils/httpClient';

export enum AlgoliaTrackingEvent {
  // eslint-disable-next-line no-unused-vars
  clickedObjectIDsAfterSearch = 'clickedObjectIDsAfterSearch',
  // eslint-disable-next-line no-unused-vars
  clickedObjectIDs = 'clickedObjectIDs',
  // eslint-disable-next-line no-unused-vars
  convertedObjectIDsAfterSearch = 'convertedObjectIDsAfterSearch',
  // eslint-disable-next-line no-unused-vars
  convertedObjectIDs = 'convertedObjectIDs',
  // eslint-disable-next-line no-unused-vars
  viewedObjectIDs = 'viewedObjectIDs',
}

export interface AlgoliaTrackingRequest {
  eventName: AlgoliaTrackingEvent;
  userToken: string;
  authenticatedUserToken?: string;
  queryID?: string;
  objectIDs: string[];
  positions?: number[];
  lang?: string;
}

export const algoliaTracking = async ({
  eventName,
  userToken,
  authenticatedUserToken,
  queryID,
  objectIDs,
  positions,
  lang,
}: AlgoliaTrackingRequest): Promise<boolean> => {
  const getDataAPI = await postDataFromAPI({
    path: `/algoliatracking`,
    lang,
    body: {
      eventName,
      userToken,
      authenticatedUserToken,
      queryID,
      objectIDs,
      positions,
    },
  });
  return getDataAPI.status == 200;
};
