import {
  clearError,
  errorCodeSelector,
  wishListAdd,
  wishListRemove,
  wishListResultsSelector,
} from '@/store/slices/wishListSlice';
import { useAppDispatch } from '@/store/store';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as showDialog from '@/utils/showDialog';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { getRedirectQuery } from '@/utils/theone';
import { ProductModel } from '@/models/Product.model';
import { currencyCode } from '@/constants/defaultValue.constant';
import { customerInfoSelector } from '@/store/slices/authenSlice';

interface Props {
  product: ProductModel;
  size?: number;
  text?: boolean;
}

export default function WishListProduct({ product, size = 0.8, text }: Props) {
  const dispatch = useAppDispatch();
  const errorCode = useSelector(errorCodeSelector);
  const wishListResults = useSelector(wishListResultsSelector);
  const { t } = useTranslation('');
  const router = useRouter();
  const active = wishListResults.find((item) => item === product.sku) != null;
  const customerInfo = useSelector(customerInfoSelector);
  const customerId = customerInfo?.custId;

  useEffect(() => {
    if (errorCode) {
      switch (errorCode) {
        case '401':
          showDialog
            .showMessageWithButton({
              text: t('pleaseloginbeforeadd'),
              primaryButton: {
                name: t('signin'),
                callBack: () => {
                  router.push(
                    `/${router.locale}/signin${getRedirectQuery(
                      router.asPath,
                    )}`,
                  );
                },
              },
              secondaryButton: {
                name: t('cancel'),
              },
            })
            .then(() => {
              dispatch(clearError());
            });
          break;
        default:
          showDialog
            .showErrorWithOkButton({
              text: t('thesystemistemporarilypleasetryagainlater'),
            })
            .then(() => {
              dispatch(clearError());
            });
          break;
      }
    }
  }, [errorCode, t, dispatch, router]);

  const wlistListClick = (e: any) => {
    e.preventDefault();
    if (active) {
      dispatch(wishListRemove({ sku: product.sku }));
    } else {
      dispatch(wishListAdd({ sku: product.sku }));
      try {
        if ((window as any).dataLayer && customerId !== undefined) {
          (window as any).dataLayer.push({
            event: 'addToWishList',
            userId: customerId,
            ecommerce: {
              currency: currencyCode,
              value: product.price
                ? product.price?.replaceAll(',', '')?.replaceAll('.00', '')
                : '0',
              products: [
                {
                  name: product.name,
                  id: product.sku,
                  price: product.price
                    ? product.price?.replaceAll(',', '')?.replaceAll('.00', '')
                    : '0',
                  brand: product.brand ?? '',
                  category: product.class ?? '',
                  quantity: 1,
                },
              ],
            },
          });
        }
      } catch (_) {}
      try {
        if ((window as any).appier && customerId !== undefined) {
          (window as any).appier('event', 'product_added_to_wishlist', {
            product_id: product.sku,
            category_name: product.catName ?? product.class,
            product_name: product.name,
            product_brand: product.brand,
            product_price: Number(
              product.price
                ? product.price?.replaceAll(',', '')?.replaceAll('.00', '')
                : '0',
            ),
            product_image: product.pimImage
              ? `https://${process.env.NEXT_PUBLIC_BASE_URL_PIM_DOMAIN}/TWDPIM/web/Thumbnail/${product.pimImage}`
              : undefined,
            product_link: product?.slugname
              ? `${process.env.NEXT_PUBLIC_BASE_URL_WEB}/product/${product?.slugname}`
              : undefined,
            env: process.env.NEXT_PUBLIC_NODE_ENV,
          });
        }
      } catch (_) {}
    }
  };

  return (
    <div
      className="flex items-center cursor-pointer"
      style={{
        height: `${size + size / 2}rem`,
      }}
      onClick={wlistListClick}
    >
      <svg
        viewBox="0 0 24 21"
        fill={active ? '#D0011B' : 'none'}
        xmlns="http://www.w3.org/2000/svg"
        className="wistlistIcon"
        style={{
          marginTop: (size ?? 0.75) * 3,
          fontSize: `${size}rem`,
          lineHeight: `${size}rem`,
          height: `${size}rem`,
          width: `${size}rem`,
        }}
      >
        <path
          d="M20.8401 2.60999C20.3294 2.099 19.7229 1.69364 19.0555 1.41708C18.388 1.14052 17.6726 0.998169 16.9501 0.998169C16.2276 0.998169 15.5122 1.14052 14.8448 1.41708C14.1773 1.69364 13.5709 2.099 13.0601 2.60999L12.0001 3.66999L10.9401 2.60999C9.90843 1.5783 8.50915 0.998704 7.05012 0.998704C5.59109 0.998704 4.19181 1.5783 3.16012 2.60999C2.12843 3.64169 1.54883 5.04096 1.54883 6.49999C1.54883 7.95903 2.12843 9.3583 3.16012 10.39L4.22012 11.45L12.0001 19.23L19.7801 11.45L20.8401 10.39C21.3511 9.87924 21.7565 9.27281 22.033 8.60535C22.3096 7.93789 22.4519 7.22248 22.4519 6.49999C22.4519 5.77751 22.3096 5.0621 22.033 4.39464C21.7565 3.72718 21.3511 3.12075 20.8401 2.60999V2.60999Z"
          stroke={active ? '#D0011B' : '#B4B4B4'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {text && (
        <div
          style={{
            fontSize: `1rem`,
            lineHeight: `${(size ?? 0.75) + (size ?? 0.75) / 2}rem`,
            height: `${(size ?? 0.75) + (size ?? 0.75) / 2}rem`,
          }}
          className="ms-1 mt-[0.11rem] text-black overflow-hidden"
        >
          {t('wishlist')}
        </div>
      )}
    </div>
  );
}
