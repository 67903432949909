import { ProductModel } from '@/models/Product.model';
import React from 'react';
interface Props {
  product?: ProductModel;
}

export default function NewBadge({ product }: Props) {
  return (
    <div
      className={`bg-[#F9A01B] px-1 text-xxs tracking-wider select-none h-full flex items-center ${
        product?.isBuyMore === undefined || product.isBuyMore === false
          ? 'rounded'
          : 'rounded-l'
      }`}
    >
      <div className="text-white font-bold">NEW</div>
    </div>
  );
}
