import {
  chatAndShopUrlThaiwatsadu,
  chatAndShopUrlVfix,
} from '@/constants/defaultValue.constant';
import Button from '@mui/material/Button';
import React from 'react';

interface Props {
  height?: number;
  isNoShort?: boolean;
  atbFlag?: string;
  xsShort?: boolean;
  isShort?: boolean;
}

export default function LineButton({
  height = 1.75,
  isNoShort,
  atbFlag,
  xsShort,
  isShort,
}: Props) {
  return (
    <div
      onClick={(e: any) => {
        e.preventDefault();
        // open net tab tarbet blank
        window.open(
          `${
            ['V', 'XV'].includes(atbFlag ?? '')
              ? chatAndShopUrlVfix
              : chatAndShopUrlThaiwatsadu
          }`,
        );
      }}
    >
      <Button
        className={`text-white text-sm bg-[#00B901] hover:bg-[#45ac45] h-[${height}rem] leading-3 w-full ${
          isNoShort ? '' : 'sm:w-auto'
        } min-w-8 md:min-w-20 lg:min-w-20`}
        sx={{
          backgroundColor: '#00B901',
          color: 'white',
          '&:hover': {
            backgroundColor: '#45ac45',
          },
          height: `${height}rem`,
          fontSize: '0.75rem',
          lineHeight: '0.75rem',
          minWidth: '2rem',
          width: '100%',
          '&.MuiButton-root': !isShort
            ? {
                lg: {
                  minWidth: '4.6rem',
                },
                md: {
                  minWidth: '4.6rem',
                },
              }
            : undefined,
          sm: {
            width: isNoShort ? '100%' : 'auto',
          },
        }}
        aria-label="chat and shop"
      >
        <i className="bi bi-line text-xs h-3"></i>
        {!isShort && (
          <div
            className={`overflow-hidden ms-1 sm:lg-1 w-full line-clamp-2${
              isNoShort ? '' : ` addtocart-text${xsShort ? '-xs' : ''}`
            }`}
          >
            Chat & Shop
          </div>
        )}
      </Button>
    </div>
  );
}
