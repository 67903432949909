import { numberWithCommas } from '@/utils/format';
import { useTranslation } from 'next-i18next';
import React from 'react';
import Image from 'next/image';

interface Props {
  badgeBuyDisc: number;
}

export default function SplashBadge({ badgeBuyDisc }: Props) {
  const { t } = useTranslation();
  return (
    <>
      {badgeBuyDisc !== undefined && (
        <div className="relative">
          <div className="block sm:hidden">
            <Image
              src="/images/svg/plp/splash_mobile.svg"
              alt="gift"
              width={160}
              height={30}
              className="h-5"
              style={{ width: '100%' }}
            />
          </div>
          <div className="hidden sm:block">
            <Image
              src="/images/svg/plp/splash.svg"
              alt="gift"
              width={143}
              height={75}
              style={{ width: '100%' }}
            />
          </div>
          <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex flex-row sm:flex-col items-center">
            <div className="flex items-center">
              <div className="w-3 h-3">
                <Image
                  src="/images/svg/plp/the1.svg"
                  alt="gift"
                  width={15}
                  height={15}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="px-1 text-xxs whitespace-nowrap leading-3 text-[#fdec09]">
                {t('productcard:savenow')}
              </div>
            </div>
            <div className="font-bold text-xxs text-[#fdec09] font-price leading-3">
              {numberWithCommas(badgeBuyDisc)}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
