import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import LeftArrow from '../widgets/arrow/LeftArrow';
import RightArrow from '../widgets/arrow/RightArrow';

export enum ArrowPosition {
  // eslint-disable-next-line no-unused-vars
  top = 1,
  // eslint-disable-next-line no-unused-vars
  bottom = 2,
}

interface Props {
  children?: React.ReactNode[];
  slidesToShow: number; // xl or more and default
  slidesPerRow?: number;
  centerMode?: boolean;
  dots?: boolean;
  arrows?: boolean;
  autoplay?: boolean;
  xl?: {
    slidesToShow: number;
    slidesPerRow?: number;
    dots?: boolean;
    arrows?: boolean;
  };
  lg?: {
    slidesToShow: number;
    slidesPerRow?: number;
    dots?: boolean;
    arrows?: boolean;
  };
  md?: {
    slidesToShow: number;
    slidesPerRow?: number;
    dots?: boolean;
    arrows?: boolean;
  };
  sm?: {
    slidesToShow: number;
    slidesPerRow?: number;
    dots?: boolean;
    arrows?: boolean;
  };
  xs?: {
    slidesToShow: number;
    slidesPerRow?: number;
    dots?: boolean;
    arrows?: boolean;
  };
  infinite?: boolean;
  noArrowBg?: boolean;
  noMarginDot?: boolean;
  initialSlide?: number;
  // eslint-disable-next-line no-unused-vars
  afterChange?: (current: number) => void;
  arrowPosition?: ArrowPosition;
  isArrowInSideOnly?: boolean;
  slidesToScroll?: number;
  variableWidth?: boolean;
  marginBottom?: string;
  isArrowOutSideOnly?: boolean;
  fullHeight?: boolean;
  swipe?: boolean;
}

const ThisLeftArrow = (props: any) => <LeftArrow {...props} />;
const ThisRightArrow = (props: any) => <RightArrow {...props} />;

export default function SliderLayout({
  children,
  slidesToShow,
  slidesPerRow,
  centerMode,
  dots,
  arrows,
  autoplay,
  xl,
  lg,
  md,
  sm,
  xs,
  infinite,
  noArrowBg,
  noMarginDot,
  initialSlide,
  afterChange,
  arrowPosition,
  isArrowInSideOnly,
  slidesToScroll,
  variableWidth,
  marginBottom,
  isArrowOutSideOnly,
  fullHeight,
  swipe,
}: Props) {
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    sliderRef.current?.slickGoTo(initialSlide ?? 0);
  }, [initialSlide]);

  if (children === undefined || children.length === 0) return <></>;

  const settings = {
    swipe: swipe ?? true,
    initialSlide: initialSlide ?? 0,
    centerMode: centerMode ?? false,
    dots: dots,
    infinite: children.length > slidesToShow - 1 ? infinite ?? true : false,
    speed: 500,
    autoplay: autoplay,
    autoplaySpeed: 5000,
    slidesPerRow: slidesPerRow ?? 1,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll ?? slidesToShow,
    arrows: arrows,
    appendDots: (dots: any) => {
      return (
        <menu
          style={{
            marginBottom: marginBottom
              ? marginBottom
              : noMarginDot
              ? '0.75rem'
              : '-0.75rem',
          }}
        >
          {dots}
        </menu>
      );
    },
    customPaging: () => {
      return <div className="w-2 h-2 rounded-full sliderDot"></div>;
    },
    afterChange: afterChange
      ? (current: number) => {
          afterChange(current);
        }
      : undefined,
    prevArrow: (
      <ThisLeftArrow
        arrowPosition={arrowPosition}
        isArrowInSideOnly={isArrowInSideOnly}
        noArrowBg={noArrowBg}
        isArrowOutSideOnly={isArrowOutSideOnly}
      />
    ),
    nextArrow: (
      <ThisRightArrow
        arrowPosition={arrowPosition}
        isArrowInSideOnly={isArrowInSideOnly}
        noArrowBg={noArrowBg}
        isArrowOutSideOnly={isArrowOutSideOnly}
      />
    ),
    variableWidth: variableWidth,
    responsive: [
      {
        breakpoint: 1360,
        settings: xl
          ? {
              dots: xl.dots,
              arrows: xl.arrows ?? true,
              infinite:
                infinite === undefined
                  ? children.length > xl.slidesToShow - 1
                    ? true
                    : false
                  : false,
              slidesToShow: xl.slidesToShow,
              slidesToScroll: xl.slidesToShow,
              slidesPerRow: xl.slidesPerRow ?? 1,
            }
          : {},
      },
      {
        breakpoint: 976,
        settings: lg
          ? {
              dots: lg.dots,
              arrows: lg.arrows ?? true,
              infinite:
                infinite === undefined
                  ? children.length > lg.slidesToShow - 1
                    ? true
                    : false
                  : false,
              slidesToShow: lg.slidesToShow,
              slidesToScroll: lg.slidesToShow,
              slidesPerRow: lg.slidesPerRow ?? 1,
            }
          : {},
      },
      {
        breakpoint: 768,
        settings: md
          ? {
              dots: md.dots,
              arrows: md.arrows ?? true,
              infinite:
                infinite === undefined
                  ? children.length > md.slidesToShow - 1
                    ? true
                    : false
                  : infinite,
              slidesToShow: md.slidesToShow,
              slidesToScroll: md.slidesToShow,
              slidesPerRow: md.slidesPerRow ?? 1,
            }
          : {},
      },
      {
        breakpoint: 480,
        settings: sm
          ? {
              dots: sm.dots,
              arrows: sm.arrows ?? true,
              infinite:
                infinite === undefined
                  ? children.length > sm.slidesToShow - 1
                    ? true
                    : false
                  : infinite,
              slidesToShow: sm.slidesToShow,
              slidesToScroll: sm.slidesToShow,
              slidesPerRow: sm.slidesPerRow ?? 1,
            }
          : {},
      },
      {
        breakpoint: 380,
        settings: xs
          ? {
              dots: xs.dots,
              arrows: xs.arrows ?? true,
              infinite:
                infinite === undefined
                  ? children.length > xs.slidesToShow - 1
                    ? true
                    : false
                  : infinite,
              slidesToShow: xs.slidesToShow,
              slidesToScroll: xs.slidesToShow,
              slidesPerRow: xs.slidesPerRow ?? 1,
            }
          : {},
      },
    ],
  };

  return (
    <div className={fullHeight === true ? 'full-height' : ''}>
      <Slider ref={sliderRef} {...settings}>
        {children.map((e, i) => (
          <React.Fragment key={`slider-${i}`}>
            <div className="item-content h-full">{e}</div>
          </React.Fragment>
        ))}
      </Slider>
    </div>
  );
}
