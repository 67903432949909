import { AddToCartRequest, SkuWithQty } from '@/models/AddToCart.model';
import {
  addToCart,
  addingToCartSelector,
  addingToCartSkuSelector,
} from '@/store/slices/addToCartSlice';
import { useAppDispatch } from '@/store/store';
import Button from '@mui/material/Button';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../../common/Spinner';
import { mapSkuAddingToCart } from '@/utils/format';
import LineButton from './LineButton';
import { isHybridSelector } from '@/store/slices/storeSlice';
import { useRouter } from 'next/router';
import OutOfStockButton from './OutOfStockButton';
import NotifyButton from './NotifyButton';

interface Props {
  sku?: string;
  isLineButton?: boolean;
  atbFlag?: string;
  xsShort?: boolean;
  qty?: number;
  isNoShort?: boolean;
  withService?: string;
  //bundle
  skuQtyList?: SkuWithQty[];
  setId?: string;
  height?: number;
  isOutOfStock: boolean;
  addToCartText?: string;
  disabledBgColor?: string;
  disabledTextColor?: string;
  isSelectBundle?: boolean;
  addToCartCallback?: () => void;
  fullWidth?: boolean;
  isShort?: boolean;
  isShortWithQty?: boolean;
  isChristmas?: boolean;
  isPdp?: boolean;
  skuStatus?: string;
}

export default function AddToCartButton({
  sku,
  isLineButton,
  atbFlag,
  xsShort,
  qty,
  isNoShort,
  withService,
  skuQtyList,
  setId,
  height = 1.75,
  isOutOfStock,
  addToCartText,
  disabledBgColor,
  disabledTextColor,
  isSelectBundle,
  addToCartCallback,
  fullWidth,
  isShort,
  isShortWithQty,
  isChristmas,
  isPdp,
  skuStatus,
}: Props) {
  const { t } = useTranslation('productcard');
  const dispatch = useAppDispatch();
  const router = useRouter();
  const addingToCart = useSelector(addingToCartSelector);
  const addingToCartSku = useSelector(addingToCartSkuSelector);
  const isHybrid = useSelector(isHybridSelector);
  const isLoading: boolean =
    addingToCartSku ===
      mapSkuAddingToCart({
        sku,
        setId,
        skuQtyList,
      }) && addingToCart;
  const handleAddtocart = (e: any) => {
    e.preventDefault();
    if (!isLoading) {
      if (isChristmas) {
        router.push(`/${router.locale}/christmas`);
      } else {
        if (sku) {
          const addToCartData: AddToCartRequest = {
            sku: sku,
            qty: qty ?? 1,
            withService: withService,
            setId: setId,
            isSelectBundle: isSelectBundle,
          };
          addToCartCallback?.();
          dispatch(addToCart(addToCartData));
        } else if (skuQtyList && skuQtyList.length > 0) {
          const addToCartData: AddToCartRequest = {
            sku: '',
            skuQtyList: skuQtyList,
            qty: qty ?? 1,
            withService: withService,
            setId: setId,
            isSelectBundle: isSelectBundle,
          };
          addToCartCallback?.();
          dispatch(addToCart(addToCartData));
        }
      }
    }
  };
  const isPreOrder = ['ER'].includes(atbFlag ?? '');
  return isLineButton == true ? (
    <LineButton
      height={height}
      isNoShort={isNoShort}
      xsShort={xsShort}
      atbFlag={atbFlag}
      isShort={isShort}
    />
  ) : isOutOfStock ? (
    <div>
      {isPdp && skuStatus != 'D' ? (
        <NotifyButton height={height} isNoShort={isNoShort} sku={sku} />
      ) : (
        <OutOfStockButton height={height} isNoShort={isNoShort} />
      )}
    </div>
  ) : (
    <Button
      disabled={!sku && (skuQtyList ?? []).length <= 0}
      onClick={handleAddtocart}
      sx={{
        backgroundColor: isHybrid ? '#006E63' : '#B8292F',
        color: 'white',
        '&:hover': {
          backgroundColor: isHybrid ? '#006E63D0' : '#B8292FD0',
        },
        '&:disabled': {
          backgroundColor: disabledBgColor ?? '#EBEBEB',
          color: disabledTextColor,
        },
        height: `${height}rem`,
        fontSize: '0.75rem',
        lineHeight: '0.75rem',
        minWidth: '2rem',
        width: isShort && !isShortWithQty ? 'auto' : '100%',
        '&.MuiButton-root': isShort
          ? undefined
          : {
              lg: {
                minWidth: '4.6rem',
              },
              md: {
                minWidth: '4.6rem',
              },
            },
        sm: {
          width:
            (isNoShort === true || fullWidth === true) && !isShort
              ? '100%'
              : 'auto',
        },
      }}
      aria-label="add to cart"
    >
      {isLoading ? (
        <div className="flex">
          {isNoShort && !isShort ? (
            <></>
          ) : (
            <div
              className={`${
                isShort ? '' : `addtocart-plus${xsShort ? '-xs' : ''}`
              }`}
            >
              +
            </div>
          )}
          <div className="h-[18px] w-[29px]">
            <Spinner size={18} borderSize={4} />
          </div>
        </div>
      ) : (
        <div className={`flex${!isNoShort || isShort ? ' px-1 md:px-0' : ''}`}>
          {isNoShort && !isShort ? (
            <></>
          ) : (
            <div
              className={isShort ? '' : `addtocart-plus${xsShort ? '-xs' : ''}`}
            >
              +
            </div>
          )}
          <div className="h-[18px] w-[29px]">
            <i className="bi bi-cart text-xs h-3"></i>
          </div>
        </div>
      )}
      <div
        className={`overflow-hidden ms-1 sm:lg-1 w-full text-xs line-clamp-2 ${
          isShort
            ? 'hidden'
            : isNoShort
            ? ''
            : `addtocart-text${xsShort ? '-xs whitespace-nowrap' : ''}`
        } `}
      >
        {addToCartText ?? (isPreOrder ? 'Pre-order' : t('addtocart'))}
      </div>
    </Button>
  );
}
