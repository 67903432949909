import React from 'react';
import { useTranslation } from 'next-i18next';

interface Props {
  discountPercent?: number;
}

export default function ClearanceBadge({ discountPercent }: Props) {
  const { t } = useTranslation('');
  return discountPercent ? (
    <div
      className="bg-primary px-0.5 pb-1 text-white flex flex-col items-center justify-center"
      style={{
        clipPath:
          'polygon( calc(1rem / -2 - 5px) 0,calc(100% + 1rem / 2 + 5px) 0, calc(100% + 1rem / 2 + 5px ) calc(100% - 1rem),50% 100%,calc(1rem / -2 - 5px) calc(100% - 1rem))',
      }}
    >
      <div className="text-sm leading-4 sm:text-base sm:leading-4 lg:text-lg font-semibold text-center">
        <div className="text-white font-semibold text-xxs lg:text-xs">
          {t('clearance')}
        </div>
        <div className="flex justify-center" style={{ lineHeight: '0.6rem' }}>
          <div
            className="text-white font-semibold text-2xl"
            style={{ lineHeight: '0.6rem' }}
          >
            {discountPercent}
          </div>
          <div className="text-left my-auto">
            <div
              className="text-white font-semibold text-xs"
              style={{ lineHeight: '0.6rem' }}
            >
              %
            </div>
          </div>
        </div>
        <div
          className="text-white font-semibold text-xxs lg:text-xs"
          style={{ lineHeight: '0.8rem' }}
        >
          OFF
        </div>
      </div>
    </div>
  ) : // <div className="relative">
  //   <svg
  //     width="60"
  //     height="75"
  //     viewBox="0 0 60 75"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       fill-rule="evenodd"
  //       clip-rule="evenodd"
  //       d="M60 0H0V63L30 75L60 63V0Z"
  //       fill="#B8292F"
  //     />
  //   </svg>
  //   <div
  //     className="absolute w-full top-0 text-center"
  //     style={{ lineHeight: '0.8rem' }}
  //   >
  //     <div className="text-white font-semibold text-xxs lg:text-xs">
  //       ล้างสต้อก
  //     </div>
  //     <div className="flex leading-3 justify-center">
  //       <div className="text-white font-semibold">{discountPercent}</div>
  //       <div className="text-left my-auto">
  //         <div
  //           className="text-white font-semibold text-xxs lg:text-xs"
  //           style={{ lineHeight: '0.5rem' }}
  //         >
  //           %
  //         </div>
  //       </div>
  //     </div>
  //     <div
  //       className="text-white font-semibold text-xxs lg:text-xs"
  //       style={{ lineHeight: '0.8rem' }}
  //     >
  //       OFF
  //     </div>
  //   </div>
  // </div>
  null;
}
