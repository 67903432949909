import React from 'react';
import Image from 'next/image';

export default function FreeInstallationBadge() {
  return (
    <div>
      <Image
        src="/images/svg/plp/freeinstallation.svg"
        alt="freeinstallation"
        width={84}
        height={25}
      />
    </div>
  );
}
