import React from 'react';

interface Props {
  discountPercent?: number;
}

export default function SaleBadge({ discountPercent }: Props) {
  return discountPercent ? (
    <div className="w-6 h-6 sm:w-8 sm:h-8 lg:w-9 lg:h-9 xl:w-10 xl:h-10 bg-primary text-white flex flex-col items-center justify-center rounded-full">
      <div className="text-sm h-3 sm:h-4 leading-3 sm:text-base sm:leading-4 lg:text-lg lg:leading-4 font-semibold">
        -{discountPercent}%
      </div>
    </div>
  ) : null;
}
