import React from 'react';
import heroBannerClass from '@/styles/herobanner.module.css';
import { ArrowPosition } from '@/components/common/SliderLayout';

interface Props {
  onClick: () => void | null;
  noArrowBg?: boolean;
  overrideClassName?: string;
  arrowPosition?: ArrowPosition;
  isArrowInSideOnly?: boolean;
  isArrowOutSideOnly?: boolean;
}

export default function LeftArrow({
  onClick,
  noArrowBg,
  overrideClassName,
  arrowPosition,
  isArrowInSideOnly,
  isArrowOutSideOnly,
}: Props) {
  return onClick ? (
    <>
      <div
        className={`${
          arrowPosition === ArrowPosition.top
            ? heroBannerClass.buttonLayoutTop
            : heroBannerClass.buttonLayout
        } ${
          isArrowInSideOnly
            ? 'left-0'
            : isArrowOutSideOnly
            ? '-left-2'
            : heroBannerClass.buttonPrevious
        } ${!noArrowBg && heroBannerClass.buttonBg}`}
        onClick={onClick}
      >
        <div className={`${heroBannerClass.arrowLayout} ${overrideClassName}`}>
          <i className="bi bi-chevron-left"></i>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}
