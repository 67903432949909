import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { ProductModel } from '@/models/Product.model';

interface Props {
  product: ProductModel;
}

export default function BuyMoreBadge({ product }: Props) {
  const { i18n } = useTranslation();
  return (
    <>
      {((product.priceMsgTierPlp?.length ?? 0) > 0 ||
        (product.priceMsgBonusPlp?.length ?? 0) > 0) && (
        <div
          className="bg-gradient-to-t from-[#F9A01B] to-primary pe-1.5 sm:pe-2 py-0 tracking-wider select-none whitespace-nowrap h-full flex items-center"
          style={{
            clipPath: 'polygon(100% 0,90% 100%, 0 100%, 0 0)',
          }}
        >
          <div className="flex items-center">
            <div className="leading-3 ms-0.5 sm:ms-1 text-white font-bold ">
              <Image
                src={`/images/badge/${
                  (product.priceMsgTierPlp?.length ?? 0) > 0
                    ? 'buymore'
                    : (product.priceMsgBonusPlp?.length ?? 0) > 0
                    ? 'packprice'
                    : ''
                }_${i18n.language === 'th' ? 'th' : 'en'}.svg`}
                alt={product.priceMsgTierPlp ? 'buymore' : 'packprice'}
                width={89}
                height={24}
                style={{ maxWidth: '100%' }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
