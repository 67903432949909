import React from 'react';
import SliderLayout from '@/components/common/SliderLayout';
import { numberWithCommas } from '@/utils/format';
import PriceMsgTierPlp from './PriceMsgTierPlp';
import { ProductModel } from '@/models/Product.model';
import { useTranslation } from 'next-i18next';

interface Props {
  product: ProductModel;
}

export default function PriceMsgSlide({ product }: Props) {
  const { t } = useTranslation('productcard');
  const tierLength = product.priceMsgTierPlp?.length;
  const bonusLength = product.priceMsgBonusPlp?.length;
  return (
    <div className="mb-1.5">
      <SliderLayout
        infinite={false}
        dots={false}
        arrows={true}
        autoplay={false}
        slidesToShow={
          tierLength
            ? tierLength === 1
              ? 2
              : 2.5
            : bonusLength === 1
            ? 2
            : 2.5
        }
        xs={{ slidesToShow: 1.25 }}
        sm={{ slidesToShow: 1.5 }}
        md={{ slidesToShow: 2.25 }}
        lg={{
          slidesToShow: tierLength
            ? tierLength === 1
              ? 2
              : 2.5
            : bonusLength === 1
            ? 2
            : 2.5,
        }}
        fullHeight
      >
        {[
          ...(product.priceMsgTierPlp?.map((priceMsgTierPlp, index) => (
            <div key={index} className="pe-1.5">
              <PriceMsgTierPlp
                product={product}
                priceMsgTierPlp={priceMsgTierPlp}
              />
            </div>
          )) ?? []),
          ...(product.priceMsgBonusPlp?.map((priceMsgBonusPlp, index) => (
            <div key={index} className="pe-1.5">
              <PriceMsgTierPlp
                product={product}
                priceMsgBonusPlp={priceMsgBonusPlp}
              />
            </div>
          )) ?? []),
          <div key="default">
            <div className="bg-white border rounded-md w-full col-span-1 mb-1 p-1.5 z-10 text-xs leading-3">
              <div className="whitespace-nowrap font-semibold">
                1 {t('common:each')}
              </div>
              <div className="flex justify-end items-end">
                <div className="text-center text-primary text-[24px] mt-1 font-price">
                  {numberWithCommas(product.price)}
                </div>
                <div className="text-right text-grayDark3 mt-0.5">
                  /{product.unitName}
                </div>
              </div>
            </div>
          </div>,
        ]}
      </SliderLayout>
    </div>
  );
}
