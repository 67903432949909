import { numberWithCommas } from '@/utils/format';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

interface Props {
  name?: string;
  currentValue: number;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: number) => void;
  minQty?: number;
  stock?: number;
  height?: number;
  isShowOnly?: boolean;
  canZero?: boolean;
  maxQty?: number;
  setZero?: boolean;
  packSize?: number;
  value?: number;
  message?: string;
}

export default function ProductCounter({
  name,
  currentValue,
  onChange,
  minQty,
  height,
  isShowOnly,
  canZero,
  maxQty,
  setZero,
  packSize,
  value,
  message,
}: Props) {
  const { t } = useTranslation();
  height = height ?? 1.5;
  const minQuntity: number | undefined =
    minQty !== undefined || packSize !== undefined
      ? (packSize ?? 1) > (minQty ?? 1)
        ? packSize
        : minQty
      : undefined;
  const [counter, setCounter] = useState<number>(currentValue);
  const [isMax, setIsMax] = useState<boolean>(false);
  const [isMin, setIsMin] = useState<boolean>(false);
  const handleMinus = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (counter - (canZero === true ? 0 : 1) > 0) {
      handleChange(counter - (packSize ?? 1));
    }
  };
  const handlePlus = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleChange(counter + (packSize ?? 1));
  };
  const handleChange = (value: number) => {
    if (value > 0) {
      if (value !== currentValue) {
        if (maxQty && value > maxQty) {
          setCounter(maxQty);
          onChange(maxQty);
          setIsMax(true);
        } else if (minQuntity && value < minQuntity) {
          setCounter(minQuntity);
          onChange(minQuntity);
          setIsMin(true);
        } else {
          if ((packSize ?? 1) > 1) {
            const newValue =
              Math.floor(value / (packSize ?? 1)) * (packSize ?? 1);
            setCounter(newValue);
            onChange(newValue);
          } else {
            setCounter(value);
            onChange(value);
          }
          setIsMin(false);
          setIsMax(false);
        }
      }
    } else {
      setCounter(canZero ? 0 : 1);
      if (canZero === true) {
        onChange(0);
      }
      setIsMax(false);
      if (minQuntity && value < minQuntity) {
        setCounter(minQuntity);
        onChange(minQuntity);
        setIsMin(true);
      } else {
        setIsMin(false);
      }
    }
  };

  useEffect(() => {
    if (minQuntity && minQuntity > 0 && currentValue < minQuntity) {
      setCounter(minQuntity);
      onChange(minQuntity);
    }
    if (setZero && counter != 0) {
      setCounter(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setZero, minQuntity]);

  useEffect(() => {
    if (value !== undefined) {
      setCounter(value);
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return isShowOnly ? (
    <div
      className={`border w-full rounded flex text-sm`}
      style={{
        height: `${height}rem`,
      }}
      onClick={(e) => e.preventDefault()}
    >
      <div className="mx-auto my-auto">{currentValue}</div>
    </div>
  ) : (
    <div
      className={`relative select-none ${
        maxQty !== undefined && isMax === true ? 'mb-1.5' : ''
      }`}
    >
      <div className="flex items-center">
        <div
          className={`w-1/4 bg-white ${
            counter - 1 > 0
              ? 'hover:bg-[#6c757d] hover:text-white active:bg-[#6c757dee]'
              : 'text-grayLight3'
          } px-1 text-center border flex items-center justify-center rounded-l`}
          onClick={handleMinus}
          style={{
            cursor: counter - 1 > 0 ? 'pointer' : undefined,
            height: `${height}rem`,
          }}
        >
          <i className="bi bi-dash text-xs h-3 leading-3"></i>
        </div>
        {
          <input
            type="text"
            aria-label="Quantity"
            id={name}
            name={name}
            value={counter}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(e) => {
              const value = e.target.value;
              const result = value.replace(/[^0-9]/g, '');
              setCounter(Number(result) ?? 0);
            }}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                handleChange(counter);
              }
            }}
            onBlur={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              handleChange(counter);
            }}
            className={`w-2/4 bg-white leading-${height} text-center border border-l-0 border-r-0 text-sm rounded-none`}
            style={{
              height: `${height}rem`,
            }}
          />
        }
        <div
          className={`w-1/4 bg-white hover:bg-[#6c757d] hover:text-white active:bg-[#6c757dee] px-1 text-center border flex items-center justify-center rounded-r`}
          onClick={handlePlus}
          style={{ cursor: 'pointer', height: `${height}rem` }}
        >
          <i className="bi bi-plus text-xs h-3 leading-3"></i>
        </div>
      </div>
      {maxQty !== undefined && isMax === true && (
        <div className="text-red-500 text-xxs absolute right-0 -bottom-3.5 whitespace-nowrap">
          {message
            ? message
            : `${t('onlyleft')} ${numberWithCommas(maxQty)} ${t('unit')}`}
        </div>
      )}
      {minQuntity !== undefined && isMin === true && (
        <div className="text-red-500 text-xxs absolute left-0 -top-3 whitespace-nowrap">
          {t('minimumorderamount')} {numberWithCommas(minQuntity)} {t('unit')}
        </div>
      )}
    </div>
  );
}
