import Button from '@mui/material/Button';
import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useAppDispatch } from '@/store/store';
import { setSkuNotify } from '@/store/slices/notifyMeSlice/notifyMeSlice';
import styles from '../../../../styles/notifyMe.module.css';

interface Props {
  height?: number;
  isNoShort?: boolean;
  sku?: string;
  isPlp?: boolean;
}
export default function NotifyButton({
  height = 1.75,
  isNoShort,
  sku,
  isPlp,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleOnClickNotify = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(setSkuNotify(sku));
  };

  return (
    <>
      <Button
        onClick={handleOnClickNotify}
        style={{
          backgroundColor: '#333333',
          lineHeight: '0.75rem',
          width: '100%',
          color: 'white',
          fontSize: '1rem',
          height: `${height}rem`,
          minWidth: '0',
        }}
        sx={{
          backgroundColor: '#333333',
          height: `${height}rem`,
          fontSize: '1rem',
          lineHeight: '0.75rem',
          minWidth: '0',
          width: '100%',
          sm: {
            width: isNoShort ? '100%' : 'auto',
          },
        }}
        aria-label="out of stock"
      >
        <div className={`w-5 ${isPlp ? `${styles.NotifyMeTextImage}` : ''}`}>
          <Image
            src="/images/logo/bellnotify.svg"
            alt="bellnotify"
            width={24}
            height={24}
          />
        </div>
        {isPlp ? (
          <div>
            <div
              className={`block text-xs text-white text-center overflow-hidden leading-3 w-full line-clamp-1 px-0 sm:px-0.5 ${styles.NotifyMeTextFull}`}
            >
              {t('productcard:notifywhenavailable')}
            </div>
            <div
              className={`hidden text-white text-xs text-center leading-3 overflow-hidden w-full line-clamp-2 px-0.5 md:px-1 sm:px-1.5 ${styles.NotifyMeTextShort}`}
            >
              {t('productcard:notification')}
            </div>
          </div>
        ) : (
          <div
            className={
              'overflow-hidden w-full leading-3 line-clamp-2 px-0 sm:px-1.5'
            }
          >
            {t('productcard:notifywheninstock')}
          </div>
        )}
      </Button>
    </>
  );
}
