import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import styles from '../../../styles/bulkySamedayBadge.module.css';

interface Props {
  canBulky?: boolean;
}

export default function BulkySameDayBadge({ canBulky }: Props) {
  const { t, i18n } = useTranslation();
  return canBulky ? (
    <div className="bg-gradient-to-r from-[#FFDEDE] via-[#FFEDD2] to-[#FFDEDE00] rounded-l-full flex items-center my-auto">
      <div className={`${styles.bulkyImage}`}>
        <div
          className={`${styles.full} ${
            i18n.language === 'en' ? styles.fullen : ''
          }`}
        >
          <Image
            src={'/images/svg/plp/bulky_same_day_badge.svg'}
            alt="bulky_same_day_badge"
            width={57}
            height={41}
          />
        </div>
        <div className={`${styles.small}`}>
          <Image
            src={'/images/svg/plp/bulky_same_day_fast.svg'}
            alt="bulky_same_day_badge"
            width={42}
            height={42}
          />
        </div>
      </div>
      <div
        className={`text-primary ps-1 font-semibold text-sm sm:text-base leading-3 sm:leading-3 flex flex-wrap ${styles.samedayBadgeText}`}
      >
        <div
          className={`leading-3 ${styles.full} ${
            i18n.language === 'en' ? styles.fullen : ''
          }`}
        >
          {t('productcard:sameday')}
        </div>
        <div
          className={`leading-3 whitespace-nowrap ${styles.small} ${
            i18n.language === 'en' ? styles.smallen : ''
          }`}
        >
          {t('productcard:delivery')}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
