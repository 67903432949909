import React from 'react';
import { useTranslation } from 'next-i18next';
import { MsgBonus, Msgtier } from '@/models/pdp/GetPDPBadge.model';
import { ProductModel } from '@/models/Product.model';
import { numberWithCommas } from '@/utils/format';
import styles from '../../../styles/textBadgeSize.module.css';

interface Props {
  product: ProductModel;
  priceMsgTierPlp?: Msgtier;
  priceMsgBonusPlp?: MsgBonus;
}

export default function PriceMsgTierPlp({
  product,
  priceMsgTierPlp,
  priceMsgBonusPlp,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="bg-white border rounded-md p-1.5 z-10">
      <div className="text-xs leading-3">
        {priceMsgTierPlp && (
          <div className="whitespace-nowrap font-semibold">
            {priceMsgTierPlp ? priceMsgTierPlp.minValue : ''}
            <span className={`${styles.textSizeFull}`}>
              &nbsp;
              {priceMsgTierPlp ? `${t('common:piecesormore')}` : ' '}
            </span>
            <span className={`${styles.textSizeShort}`}>
              {t('common:each')}
            </span>
          </div>
        )}
        {priceMsgBonusPlp && (
          <div className="whitespace-nowrap font-semibold">
            {t('common:every')} {priceMsgBonusPlp && priceMsgBonusPlp.minValue}{' '}
            {t('common:each')}
          </div>
        )}
        <div className="flex justify-end items-end">
          <div className="text-center text-primary text-[24px] mt-1 font-price">
            {numberWithCommas(
              priceMsgTierPlp
                ? priceMsgTierPlp.proPrice
                : priceMsgBonusPlp
                ? priceMsgBonusPlp.proPrice
                : '',
            )}
          </div>
          <div className="text-right text-grayDark3 mt-0.5 whitespace-nowrap">
            /{product.unitName}
          </div>
        </div>
      </div>
    </div>
  );
}
